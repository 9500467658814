import React, { useState } from 'react';
import { baseUrl } from '../shared';
import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Titillium Web:300,400,700', 'sans-serif']
  }
});

const QRCodeGenerator = () => {
  const [text, setText] = useState('');
  const [logo, setLogo] = useState(null);
  const [qrCodeUrl, setQrCodeUrl] = useState(null);

  const handleLogoUpload = (e) => {
    setLogo(e.target.files[0]);
  };

  const handleGenerateQRCode = async () => {
    const formData = new FormData();
    formData.append('text', text);
    if (logo) {
      formData.append('logo', logo);
    }

    const response = await fetch(baseUrl + 'v1/generate-qr/', {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      const qrBlob = await response.blob();
      const qrUrl = URL.createObjectURL(qrBlob);
      setQrCodeUrl(qrUrl);
    } else {
      console.error('Error generating QR code');
    }
  };

  const handleDownloadQRCode = () => {
    const link = document.createElement('a');
    link.href = qrCodeUrl;
    link.download = 'qrcode.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <h1 className="font-Aleo text-3xl font-bold mb-4">Maak 'n QR-kode</h1>
      <input
        type="text"
        placeholder="Tik of plak die teks of adres hier."
        value={text}
        onChange={(e) => setText(e.target.value)}
        className="p-2 border border-gray-300 rounded mb-6 w-full sm:w-1/4"
      />

      {/* <label className="justify-center bg-gray-100 ">
        <span className="text-gray-700 justify-center">Ander logo? (Opsioneel):</span><p>
        <div class="flex items-center justify-center w-full">
    <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
        <div class="flex flex-col items-center justify-center pt-5 pb-6">
            <svg class="w-8 h-4 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
            </svg>
            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
            <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
        </div>
        <input id="dropzone-file" type="file" class="hidden" />
    </label>
</div>  */}
        {/* <button className="flex flexcol items-center mt-10 w-full justify-center bg-cyan-900 text-white py-2 mt-4 px-4 rounded-lg hover:bg-cyan-800" onClick={document.getElementById('getFile').click()}>Kies ander logo</button> */}
        {/* <input type='file' id="getFile" className='hidden' accept="image/png, image/jpeg" onChange={handleLogoUpload}/>   */}
        {/* <input
   type="file"
   className="text-sm text-stone-500
   file:mr-5 file:py-1 file:px-3 file:border-[1px]
   file:text-xs file:font-medium
   file:bg-stone-50 file:text-stone-700
   hover:file:cursor-pointer hover:file:bg-blue-50
   hover:file:text-blue-700"
   accept="image/png, image/jpeg"
   onChange={handleLogoUpload}
/> */}
        {/* <input
          type="file"
          accept="image/png, image/jpeg"
          onChange={handleLogoUpload}
          className="mt-2 center-item"
        /> */}
{/*         
      </p>
      </label> */}

      <button
        onClick={handleGenerateQRCode}
        className="bg-cyan-800 text-white py-2 px-4 rounded mt-4 hover:bg-cyan-600"
      >
        Genereer QR-kode
      </button>

      {qrCodeUrl && (
        <div className="mt-6 justify-center">
          <img src={qrCodeUrl} alt="Die QR-kode" className="w-64 h-64 object-cover mb-4" />
        <div className="mt-6 object-left">
          <center>
          <button
            onClick={handleDownloadQRCode}
            className="mt-2 object-center bg-cyan-800 text-white py-2 justify-item justify-center px-4 rounded hover:bg-cyan-600" >
            Laai die QR-kode af
          </button>
          </center>
        </div>
        </div>
      )}
        </div>
  );
};

export default QRCodeGenerator;
