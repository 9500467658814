import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { Link, useParams, useNavigate } from "react-router-dom";
import languageMapper from "../shared";
import Logo from "../logo512.png";
import { useTranslation } from "react-i18next";
import "../i18n";
import { create } from "zustand";
import { MathJax } from "better-react-mathjax";
import { baseUrl } from "../shared";
import SharedStateContext from "../context/sharedstate";
import Navbar from "../navbar";
import Navbaranonuser from "../navbaranonuser";

function PasswordResetForm() {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const { auth } = useContext(AuthContext); // Access the auth state
    const { t, i18n } = useTranslation();
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          handleSubmit(event);
        }
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setMessage("Passwords don't match");
            return;
        }
        const response = await fetch(baseUrl + `v1/auth/password_reset/confirm/${token}/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ password }),
        });
        const data = await response.json();
        setMessage(data.message || data.error);
    };

    return (
        // <form onSubmit={handleSubmit}>
        //     <label>New Password:</label>
        //     <input
        //         type="password"
        //         value={password}
        //         onChange={(e) => setPassword(e.target.value)}
        //         required
        //     />
        //     <label>Confirm New Password:</label>
        //     <input
        //         type="password"
        //         value={confirmPassword}
        //         onChange={(e) => setConfirmPassword(e.target.value)}
        //         required
        //     />
        //     <button type="submit">Reset Password</button>
        //     <p>{message}</p>
        // </form>
<div className="min-h-screen bg-gray-100">
     {/* {auth.userRole === "editor" && <Navbar />}
     {auth.userRole !== "editor" && <Navbaranonuser />} */}
     {localStorage.setItem("bladsy", "PasswordResetForm")}
     {auth.userName === null && <Navbaranonuser />}
     {auth.userName != null && <Navbar />}      

     <div className="flex flex-col justify-center items-center p-2">
       <h1 className="text-4xl font-bold mb-6">
         <Link to={"/"}>
           {/* <a href="/" className="inline-block">  */}
           {t("reset_password")}
           {/* </a> */}
         </Link>
       </h1>
       <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg p-3">
         <form onSubmit={handleSubmit}>
           <label>{t("new_password")}:</label>
           <input
             type="password"
             value={password}
             onChange={(e) => setPassword(e.target.value)}
             required
             onKeyDown={handleKeyDown}
             placeholder={t("password")}
             className="border border-2 p-2 mb-4 w-full max-w-3xl rounded-lg"
           />
           <label>{t("confirm_new_password")}:</label>
           <input
             type="password"
             value={confirmPassword}
             onChange={(e) => setConfirmPassword(e.target.value)}
             required
             onKeyDown={handleKeyDown}
             placeholder={t("password")}
             className="border border-2 p-2 mb-4 w-full max-w-3xl rounded-lg"
           />
           <button
             type="submit"
             className="w-full bg-cyan-700 text-white py-2 mt-4 px-4 rounded-lg hover:bg-cyan-800"
           >
             {t("reset_password")}
           </button>
           <p>{message}</p>
         </form>
       </div>
     </div>
     <div className="flex flex-col mt-4 justify-center items-center p-4">
       <Link to={"/"}>
         <img src={Logo} alt="Logo" width="100" height="100" />
       </Link>
     </div>
   </div>

    );
}

export default PasswordResetForm;
