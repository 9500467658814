import React, { useState, useContext } from "react";
import { baseUrl } from "../shared";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import validator from "validator";
import Navbar from "../navbar";
import Navbaranonuser from "../navbaranonuser";
import Logo from "../logo512.png";
import { useTranslation } from "react-i18next";
import "../i18n";

function Register() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [emailuser, setEmailuser] = useState("");
  const [emailagain, setEmailagain] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [userClass, setUserClass] = useState("r_user"); // Default to regular user
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [geldig, setGeldig] = useState(false);
  const { auth } = useContext(AuthContext); // Access the auth state
  const { t, i18n } = useTranslation();

  const [message, setMessage] = useState("");
  const [messageagain, setMessageagain] = useState("");
  const validateEmail = (e) => {
    const email = e.target.value;
    if (validator.isEmail(email)) {
      setEmailuser(email);
      setGeldig(true);
    } else {
      setGeldig(false);
      setMessage(t("message_valid"));
    }
  };

  const validateEmailagain = (e) => {
    const email = e.target.value;
    if (email === emailuser) {
      setGeldig(true);
    } else {
      setGeldig(false);
      setMessageagain(t("message_notsame"));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (geldig) {
      try {
        // Check for existing username
        const usernameCheck = await fetch(`${baseUrl}v1/check_username/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username }),
        });
  
        const usernameData = await usernameCheck.json();
        if (usernameData.exists) {
          setError(t("message_username"));
          return;
        }
  
        // Check for existing email
        const emailCheck = await fetch(`${baseUrl}v1/check_email/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ emailuser }),
        });
  
        const emailData = await emailCheck.json();
        if (emailData.exists) {
          setError(t("message_email"));
          return;
        }
  
        // Proceed with registration if checks pass
        const response = await fetch(`${baseUrl}v1/register/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username,
            password,
            firstname,
            lastname,
            emailuser,
            user_class: userClass,
          }),
        });
  
        if (response.ok) {
          setSuccess(true);
          setError(null);
        } else {
          const data = await response.json();
          setError(data.error || "Registration failed");
        }
      } catch (err) {
        setError("Something went wrong");
      }
    } else {
      setError(message + "  " +  messageagain);
    }
  };
  

  return (
    <div className="min-h-screen bg-gray-100 border-solid border-width: 4px border-red-50">
      {/* {auth.userRole === "editor" && <Navbar />}
      {auth.userRole !== "editor" && <Navbaranonuser />} */}
      {localStorage.setItem("bladsy", "Register")}
u     {auth.userName === null && <Navbaranonuser />}
      {auth.userName != null && <Navbar />}      

      <div className="flex flex-col justify-center items-center p-2 border-solid border-width: 4px border-red-500">
        <h1 className="text-4xl font-bold mb-6 border-solid border-width: 4px border-red-50">
          <Link to={"/"}>
            {/* <a href="/" className="inline-block">  */}
            {t("register")}
            {/* </a> */}
          </Link>
        </h1>
        <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg p-0">
          {error && <p className="text-red-500">&nbsp; {error} &nbsp;</p>}
          {success && (
            <p className="text-green-500">
              Registration successful! Please <Link to="/login"> log in</Link>.
            </p>
          )}

          <form
            onSubmit={handleSubmit}
            className="bg-white p-8 rounded shadow-lg"
          >
            <div className="mb-4">
              <label className="block text-gray-700">{t("username")}:</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="border p-2 w-full"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">{t("password")}:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border p-2 w-full"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">{t("firstname")}:</label>
              <input
                type="text"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                className="border p-2 w-full"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">{t("surname")}:</label>
              <input
                type="text"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                className="border p-2 w-full"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">{t("emailaddress")}:</label>
              <input
                type="text"
                value={emailuser}
                onChange={(e) => {
                  setEmailuser(e.target.value);
                  validateEmail(e);
                }}
                className="border p-2 w-full"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                {t("typeagain")}:
              </label>
              <input
                type="text"
                value={emailagain}
                onChange={(e) => {
                  setEmailagain(e.target.value);
                  validateEmailagain(e);
                }}
                className="border p-2 w-full"
                required
              />
            </div>
            <button type="submit"  
                    className="flex flexcol items-center mt-10 w-full justify-center bg-cyan-900 text-white py-2 mt-4 px-4 rounded-lg hover:bg-cyan-800" >
              {t("register")}
            </button>
          </form>
        </div>
        <div className="flex flex-col mt-4 justify-center items-center p-4">
          <Link to={"/"}>
            <img src={Logo} alt="Logo" width="100" height="100" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Register;
