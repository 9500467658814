import React, { createContext, useState, useEffect } from 'react';

// Create AuthContext
export const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    accessToken: null,
    userRole: null,
    userName: null,
  });

  // On mount, check for an existing token (in local storage, for example)
  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    const refresh = localStorage.getItem('refreshToken');
    const uname = localStorage.getItem('userName');
    const role = localStorage.getItem('userRole');


    if (token) {
      setAuth({
        accessToken: token,
        refreshToken: refresh,
        userName: uname,
        userRole: role || 'anon',
      });
    }
  }, []);

  const login = (token, refresh, uname, role) => {
    // Store the token and role in localStorage (or sessionStorage)
    localStorage.setItem('accessToken', token);
    localStorage.setItem('refreshToken', refresh);
    localStorage.setItem('userName', uname);
    localStorage.setItem('userRole', role);

    // Update the auth state
    setAuth({
      accessToken: token,
      refreshToken: refresh,
      userName: uname,
      userRole: role,
    });
  };

  const logout = () => {
    // Clear the token and role from localStorage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userRole');

    // Reset auth state
    setAuth({
      accessToken: null,
      refreshToken: null,
      userName: null,
      userRole: 'anon',
    });
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
