import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../i18n";
import { baseUrl, getValidAccessToken } from "../shared";
import SharedStateContext from "../context/sharedstate";
import Navbar from "../navbar";
import Navbaranonuser from "../navbaranonuser";
import { AuthContext } from "../context/AuthContext";
import Logo from "../logo512.png";

function AddRequest() {
  const { auth } = useContext(AuthContext);
  const { t } = useTranslation();
  const { interfaceLanguage } = useContext(SharedStateContext);
  const navigate = useNavigate();
  const location = useLocation();

  // Get query parameters from the URL
  const queryParams = new URLSearchParams(location.search);
  const initialWord = queryParams.get("term") || "";
  const initialLanguage = queryParams.get("language") || "";
  const initialSuggested = queryParams.get("suggested") || "";
  const termId = queryParams.get("termId") || "";

  // Form state
  const [word, setWord] = useState(initialSuggested);
  const [wordEnglish, setWordEnglish] = useState(initialWord || "");
  const [definition, setDefinition] = useState("");
  const [field, setField] = useState("");
  const [school, setSchool] = useState("");
  const [attribution, setAttribution] = useState("");
  const [status, setStatus] = useState("PND");
  const [pos, setPos] = useState("unknown");
  const [language, setLanguage] = useState(initialLanguage);
  const [example, setExample] = useState("");
  const [date_edited, setDate_edited] = useState("");
  const [date_accepted, setDate_accepted] = useState("");
  const [synonym, setSynonym] = useState("");
  const date = new Date(); // Current date and time
  const isoDate = date.toISOString(); // Convert to ISO 8601 format
  const [editor, setEditor] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //   const handleSubmit = async (e) => {
  //     e.preventDefault();
  //     try {
  //       await fetch(baseUrl + "v1/addword/", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           word,
  //           wordEnglish,
  //           definition,
  //           example,
  //           field,
  //           school,
  //           language,
  //           attribution,
  //           status,
  //         }),
  //       });
  //       navigate("/");
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   };

  function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${year}-${month}-${date}`;
  }

  const handleSearch = async () => {
    setIsLoading(true); // Start loading
    const response = await fetch(
      baseUrl + `v1/soek/Eng/Eng/${encodeURIComponent(wordEnglish)}/false`
    );
    const data = await response.json();
    if (data["Terme in databasis"].length < 1) {
      handleSubmitEnglish();
      setIsLoading(false); // End loading
    } else {
      setIsLoading(false); // End loading
    }
  };

  const handleSubmitEnglish = async (e) => {
    setDate_edited(isoDate);
    setEditor(localStorage.getItem("userName"));
    if (status === "ACC") {
      setDate_accepted(isoDate);
    }
    const token = await getValidAccessToken(); // Get valid token
    try {
      await fetch(baseUrl + "v1/addword/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          word: wordEnglish,
          wordEnglish,
          pos,
          field,
          school,
          language: "Eng",
          attribution,
          status,
          date_edited: getDate(),
          editor: localStorage.getItem("userName"),
        }),
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const handleSubmitWord = async (e) => {
  //   // setDate_edited(isoDate);

  //   console.log("iso and edited",isoDate,localStorage.getItem("userName"),date_edited,editor)

  //   const token = await getValidAccessToken(); // Get valid token

  //   try {
  //     await fetch(baseUrl + "v1/addword/", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         'Authorization': `Bearer ${token}`,
  //       },
  //       body: JSON.stringify({
  //         word,
  //         wordEnglish,
  //         pos,
  //         definition,
  //         example,
  //         field,
  //         school,
  //         language,
  //         attribution,
  //         status,
  //         "date_edited":getDate(),
  //         "editor":localStorage.getItem("userName"),
  //       }),
  //     });
  //     navigate(0);
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (language !== "Eng") {
      handleSearch();
    }
    handleSubmitWord();
  };

  const handleSubmitWord = async (e) => {
    e.preventDefault();
    const token = await getValidAccessToken(); // Get valid token

    try {
      const addWordResponse = await fetch(baseUrl + "v1/addword/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          word,
          wordEnglish,
          pos,
          definition,
          example,
          field,
          school,
          language,
          attribution,
          status,
          date_edited: getDate(),
          editor: localStorage.getItem("userName"),
        }),
      });

      if (!addWordResponse.ok) {
        throw new Error("Failed to add word");
      }

      const deleteRequestResponse = await fetch(
        `${baseUrl}v1/delete-request-term/${termId}/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!deleteRequestResponse.ok) {
        throw new Error("Failed to delete request");
      }


      navigate("/requestedterms");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Navbar based on user status */}
      {localStorage.setItem("bladsy", "AddRequest")}
      {auth.userName === null && <Navbaranonuser />}
      {auth.userName != null && <Navbar />}

      <div className="flex flex-col justify-center items-center p-3">
        <h2 className="text-3xl font-bold mb-2">{t("add_request")}</h2>
        <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg p-6 justify-center">
          <form
            onSubmit={handleSubmitWord}
            className="bg-white p-8 rounded shadow-lg"
          >
            {/* English word field */}
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>
                  {t("sourceword")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                    <strong className = "text-2xl text-teal-600">{initialWord}</strong>
                  <p>&nbsp;</p>
                  <p> English term:</p>
                </strong>
              </label>
              <input
                type="text"
                // value={wordEnglish}
                placeholder= ""
                onChange={(e) => setWordEnglish(e.target.value)}
                className="border p-2 w-full"
              />
            </div>

            {/* Word field */}
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("word_to_be_added")}:</strong>
              </label>
              <input
                type="text"
                value={word}
                onChange={(e) => setWord(e.target.value)}
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("pos")}:</strong>
              </label>
              <select
                value={pos}
                onChange={(e) => {
                  setPos(e.target.value);
                }}
                className="border p-2 w-full"
              >
                <option value="Unknown">{t("unknown")}</option>
                <option value="Noun">{t("noun")}</option>
                <option value="Verb">{t("verb")}</option>
                <option value="Adjective">{t("adjective")}</option>
                <option value="Pronoun">{t("pronoun")}</option>
                <option value="Adverb">{t("adverb")}</option>
                <option value="Preposition">{t("preposition")}</option>
                <option value="Conjunction">{t("conjunction")}</option>
                <option value="Article">{t("article")}</option>
                <option value="Interjection">{t("interjection")}</option>
              </select>
            </div>

            {/* Language select */}
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("language")}:</strong>
              </label>
              <select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                className="border p-2 w-full"
              >
                <option value="Non">--</option>
                <option value="Eng">English</option>
                <option value="Afr">Afrikaans</option>
                <option value="Zul">isiZulu</option>
                <option value="Ses">Sesotho</option>
                <option value="Sep">Sepedi</option>
                <option value="Set">Setswana</option>
                <option value="Xit">XiTsonga</option>
                <option value="Sis">SiSwati</option>
                <option value="Nde">IsiNdebele</option>
                <option value="Xho">IsiXhosa</option>
                <option value="Tsh">TshiVenḓa</option>
                <option value="Por">Portuguese</option>
                <option value="Fre">French</option>
              </select>
            </div>

            {/* English translation if language is not English
            {language !== "Eng" && (
              <div className="mb-4">
                <label className="block text-gray-700">
                  <strong>English translation:</strong>
                </label>
                <input
                  type="text"
                  value={wordEnglish}
                  onChange={(e) => setWordEnglish(e.target.value)}
                  className="border p-2 w-full"
                />
              </div>
            )} */}

            {/* Definition field */}
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("definition")}:</strong>
              </label>
              <input
                type="text"
                value={definition}
                onChange={(e) => setDefinition(e.target.value)}
                className="border p-2 w-full"
              />
            </div>

            {/* Example usage */}
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("example")}:</strong>
              </label>
              <input
                type="text"
                value={example}
                onChange={(e) => setExample(e.target.value)}
                className="border p-2 w-full"
              />
            </div>

            {/* Context (field) */}
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("context")}:</strong>
              </label>
              <input
                type="text"
                value={field}
                onChange={(e) => setField(e.target.value)}
                className="border p-2 w-full"
              />
            </div>

            {/* School */}
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("school")}:</strong>
              </label>
              <input
                type="text"
                value={school}
                onChange={(e) => setSchool(e.target.value)}
                className="border p-2 w-full"
              />
            </div>

            {/* Attribution */}
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("attribution")}:</strong>
              </label>
              <input
                type="text"
                value={attribution}
                onChange={(e) => setAttribution(e.target.value)}
                className="border p-2 w-full"
              />
            </div>

            {/* Status */}
            <div className="mb-4">
              <label className="block text-gray-700">
                <strong>{t("status")}:</strong>
              </label>
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="border p-2 w-full"
              >
                <option value="Non">--</option>
                <option value="ACC">{t("accepted")}</option>
                <option value="PND">{t("pending")}</option>
                <option value="REJ">{t("rejected")}</option>
                <option value="FLG">{t("flagged")}</option>
              </select>
            </div>

            {/* Submit button */}
            <button
              type="submit"
              className="w-full bg-cyan-700 text-white py-2 px-4 rounded-lg hover:bg-cyan-800"
            >
              {t("add")}
            </button>

            {/* Cancel button */}
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="w-1/2 mt-6 bg-rose-700 text-white py-2 px-4 rounded-lg hover:bg-rose-800"
            >
              {t("cancel")}
            </button>
          </form>
        </div>

        {/* Logo */}
        <div className="flex flex-col justify-center items-center p-4">
          <Link to={"/"}>
            <img src={Logo} alt="Logo" width="100" height="100" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AddRequest;
