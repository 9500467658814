import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl, getValidAccessToken } from "../shared";
import languageMapper from "../shared";
import { useTranslation } from "react-i18next";
import "../i18n";
import Navbaranonuser from "../navbaranonuser";
import Navbar from "../navbar";

function ListRequests() {
  const { auth } = useContext(AuthContext);
  const [requestedTerms, setRequestedTerms] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();


  useEffect(() => {
    if (auth?.userRole !== "administrator" && auth?.userRole !== "editor") {
      navigate("/"); // Redirect non-editors to home page
    } else {
      fetchRequestedTerms();
    }
  }, [auth]);

  const fetchRequestedTerms = async () => {
    const token = await getValidAccessToken(); // Get valid token

    try {
      const response = await fetch(`${baseUrl}v1/requested-terms/`, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setRequestedTerms(data.requested_terms);
      } else {
        setError(data.error || "Failed to fetch requested terms. Please log in again.");
      }
    } catch (err) {
      setError("Something went wrong while fetching terms.");
    }
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    // <div className="min-h-screen bg-gray-100 p-8">
    //   <h1 className="text-4xl font-bold mb-6">Requested Terms</h1>
    //   <div className="overflow-x-auto">
    <div className="w-full min-h-screen bg-gray-100">
      {localStorage.setItem("bladsy", "ListRequests")}
      {auth.userName === null && <Navbaranonuser />}
      {auth.userName != null && <Navbar />}

      <div className="flex flex-col justify-center items-center p-2">
        <h1 className="text-4xl font-bold mb-6">
          <Link to={"/"}>
            {/* <a href="/" className="inline-block">  */}
            {t("requestlist")}
            {/* </a> */}
          </Link>
        </h1>
        <div className="w-full max-w-6xl bg-white rounded-lg shadow-lg p-3">
          <table className="table-auto w-full bg-white shadow-lg rounded-lg">
            <thead>
              <tr className="bg-gray-200">
                <th className="p-4 text-left">{t("word")}</th>
                <th className="p-4 text-left">{t("suggested_translation")} </th>
                <th className="p-4 text-left">{t("target")} </th>
                <th className="p-4 text-left">{t("username")} </th>
                <th className="p-4 text-left">{t("date_requested")} </th>
                <th className="p-4 text-left">{t("action")} </th>
              </tr>
            </thead>
            <tbody>
              {requestedTerms.length === 0 ? (
                <tr>
                  <td colSpan="6" className="text-center p-4">
                    {t("no_requests")}.
                  </td>
                </tr>
              ) : (
                (requestedTerms.map((term) => (
                  <tr key={term.id} className="border-b">
                    <td className="p-4">{term.term}</td>
                    <td className="p-4">{term.suggested_term || "None"}</td>
                    <td className="p-4">
                      {languageMapper(term.target_language)}
                    </td>
                    <td className="p-4">{term.username}</td>
                    <td className="p-4">
                      {new Date(term.date_requested).toLocaleDateString()}
                    </td>
                    <td className="p-4">
                      <Link
                        to={`/addrequest?termId=${encodeURIComponent(
                          term.id
                        )}&term=${encodeURIComponent(
                          term.term
                        )}&language=${encodeURIComponent(
                          term.target_language
                        )}&suggested=${encodeURIComponent(
                          term.suggested_term || ""
                        )}`}
                        className="bg-cyan-700 text-white py-2 px-4 rounded hover:bg-cyan-800"
                      >
                        {t("add_translation")}
                      </Link>
                    </td>
                  </tr>
                )))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ListRequests;
