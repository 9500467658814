import React, { useState, useEffect } from 'react';
import { baseUrl, getValidAccessToken } from '../shared';

const token = await getValidAccessToken(); // Get valid token

const responses = await fetch(baseUrl +'v1/gebruikers/', {
  method: 'GET',
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    'Content-Type': 'application/json',
  },
})
.then(response => response.text())
.catch(error => console.error('Error:', error));

const UserLys = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  return(
    <div>{responses}</div>
  )}

// useEffect(() => {
//   const fetchUsers = async () => {
//     const token = localStorage.getItem('accessToken');
//     console.log("AccessToken", token);
//     try {
//       const response = await fetch('/v1/gebruikers/', {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`, // Corrected Authorization header
//         },
//       });
//       console.log("Probeer data...", response);
//       const data = await response.json();
//       const data2 = await response.text();
//       console.log('na gekry....', data2);
//       setUsers(data);
//     } catch (err) {
//       setError('Error fetching users');
//     }
//   };

//   fetchUsers();
// }, []);

// // const UserRoleManager = () => {
// //   const [users, setUsers] = useState([]);
// //   const [error, setError] = useState(null);

// //   // Fetch the list of users (ensure your backend API provides this)
// //   useEffect(() => {
// //     const fetchUsers = async () => {
// //         const token = localStorage.getItem('accessToken');
// //         console.log("AccessToken",token)
// //       try {
// //         const response = await fetch('/v1/gebruikers/', {
// //             method: 'GET',
// //             headers: {
// //               'Content-Type': 'application/json',
// //               'Authorization': `Bearer ${token}`,
// //             },
// //           });  // 
// //         console.log("Probeer...", token)
// //         if (!response.ok) {
// //           console.log(`Error: ${response.status} - ${response.statusText}`);
// //           throw new Error(`HTTP status ${response.status}`);
// //         }
// //         if (!response.ok) {
// //           console.log(`Error: ${response.status} - ${response.statusText}`);
// //           throw new Error(`HTTP status ${response.status}`);
// //         }
  
// //         // Log the raw response as text before parsing
// //         const rawResponse = await response.text();
// //         console.log('Raw Response:', rawResponse);
  
// //         // After logging, try to parse the raw response as JSON
// //         const contentType = response.headers.get('content-type');
// //         console.log('Content-Type:', contentType);
  
// //         if (contentType && contentType.includes('application/json')) {
// //           const data = JSON.parse(rawResponse);
// //           console.log('Received data:', data);
// //           setUsers(data);
// //         } else {
// //           console.log('Response is not JSON or no content-type set');
// //           throw new Error('Response is not JSON or content-type is missing');
// //         }
// //       } catch (err) {
// //         console.error('Error occurred:', err);
// //         setError('Error fetching users');
// //       }
// //     };
  
// //     //     if (response.ok) {
// //     //       console.log(`!Error: ${response.status} - ${response.statusText}`);
// //     //       throw new Error(`!HTTP status ${response.status}`);
// //     //     }
// //     //     const data = await response.json();
// //     //     console.log(data)
// //     //     setUsers(data);
// //     //     console.log("Users: ", users)
// //     //   } catch (err) {
// //     //     setError('Error fetching users');
// //     //   }
// //     // };

// //     fetchUsers();
// //   }, []);

// //   // Function to change user role
// //   const changeUserRole = async (userId) => {
// //     const token = localStorage.getItem('accessToken');
// //     console.log("AccessToken",token)
// //     try {
// //       const response = await fetch(`/v1/change-role/${userId}/`, {
// //         method: 'POST',
// //         headers: {
// //           'Content-Type': 'application/json',
// //           'Authorization': `Bearer ${token}`,
// //         },
// //       });
// //       const data = await response.json();

// //       if (data.status === 'success') {
// //         // Update role in the users state
// //         setUsers(users.map(user => 
// //           user.id === userId ? { ...user, role: data.new_role } : user
// //         ));
// //       } else {
// //         alert('Error changing role');
// //       }
// //     } catch (error) {
// //       alert('Error connecting to the server');
// //     }
// //   };

// //   if (error) {
// //     return <div>{error}</div>;
// //   }

//   return (
//     <div>
//       <h2>User Role Manager</h2>
//       <table>
//         <thead>
//           <tr>
//             <th>Username</th>
//             <th>Email</th>
//             <th>Current Role</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {users.map(user => (
//             <tr key={user.id}>
//               <td>{user.username}</td>
//               <td>{user.email}</td>
//               <td>{user.role}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

export default UserLys;
