import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import languageMapper from "../shared";
import Logo from "../logo512.png";
import { useTranslation } from "react-i18next";
import "../i18n";
import { create } from "zustand";
import { MathJax } from "better-react-mathjax";
import { baseUrl } from "../shared";
import SharedStateContext from "../context/sharedstate";
import Navbar from "../navbar";
import Navbaranonuser from "../navbaranonuser";
import NoResultsModal from "./NoResultsModal"; // Import the modal component
import { Atom } from "react-loading-indicators"; // Import the loading indicator

function LandingPage({ isLoggedIn, onLogout }) {
  const { auth } = useContext(AuthContext);
  const [phrases, setPhrases] = useState([]);
  const [gtPhrase, setGtPhrase] = useState([]);
  const [noResults, setNoResults] = useState(false); // State to show modal
  const [searchTerm, setSearchTerm] = useState("");
  const [targetLanguage, setTargetLanguage] = useState("All");
  const [sourceLanguage, setSourceLanguage] = useState("Eng");
  const [partialMatch, setPartialMatch] = useState(false);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    if (!searchTerm.trim()) {
      setError("Search term is required.");
      return; // Exit early if validation fails
    }
    setError(null);
    setIsLoading(true); // Start loading
    const response = await fetch(
      baseUrl +
        `v1/soek/${sourceLanguage}/${targetLanguage}/${encodeURIComponent(
          searchTerm
        )}/${partialMatch}`
    );
    const data = await response.json();
    if (data["Terme in databasis"].length === 0) {
      handleGtSearch();
      setNoResults(true); // Show modal if no results
      setIsLoading(false); // End loading
    } else {
      setIsLoading(false); // End loading
      setPhrases(data["Terme in databasis"]);
    }
  };

  const handleGtSearch = async () => {
    setIsLoading(true); // Start loading
    setGtPhrase("");
    const response = await fetch(
      baseUrl +
        `v1/translate_term/${sourceLanguage}/${targetLanguage}/${encodeURIComponent(
          searchTerm
        )}/${partialMatch}`
    );
    const data = await response.json();
    if (typeof data.translated_term !== 'undefined') {
    if (data.translated_term.length === 0) {
      setNoResults(true); // Show modal if no results
      setIsLoading(false); // End loading
    } else {
      setIsLoading(false); // End loading
      setGtPhrase(data.translated_term);
    }
  } else {
    setGtPhrase("undefined");
    setNoResults(true); // Show modal if no results
    setIsLoading(false); // End loading
  }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleRequestSubmit = async (suggestedTerm) => {
    const response = await fetch(`${baseUrl}v1/request-term/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        term: searchTerm,
        suggestedTerm,
        targetLanguage,
        sourceLanguage,
        username: auth?.userName || "anonymous",
      }),
    });
    const data = await response.json();
    // if (response.ok) {
    //   alert("Request submitted successfully");
    // } else {
    //   alert("Failed to submit request");
    // }
  };

  //   return (
  //     <div className="min-h-screen bg-gray-100">
  //       {/* other content */}
  //       <NoResultsModal
  //         isOpen={noResults}
  //         onClose={() => setNoResults(false)}
  //         searchTerm={searchTerm}
  //         targetLanguage={targetLanguage}
  //         onSubmit={handleRequestSubmit}
  //       />
  //     </div>
  //   );
  // }

  // function LandingPageou({ isLoggedIn, onLogout }) {
  //   const { auth } = useContext(AuthContext);
  //   console.log("auth.accessToken in landingpage: ", auth);

  //   const [userRole, setUserRole] = useState(null);
  //   const { t, i18n } = useTranslation();
  //   const { interfaceLanguage, setInterfaceLanguage } = useContext(SharedStateContext);
  //   const [sourceLanguage, setSourceLanguage] = useState("Eng");
  //   const [targetLanguage, setTargetLanguage] = useState("All");
  //   const [partialMatch, setPartialMatch] = useState(false);
  //   const [searchTerm, setSearchTerm] = useState("");
  //   const [phrases, setPhrases] = useState([]);
  //   console.log("taal", localStorage.getItem("taal"));
  //   // setInterfaceLanguage(localStorage.getItem("taal"));

  //   const handleSearchou = async () => {
  //     console.log(partialMatch, "PartialMatch");
  //     const response = await fetch(
  //       baseUrl +
  //         `v1/soek/${sourceLanguage}/${targetLanguage}/${encodeURIComponent(
  //           searchTerm
  //         )}/${partialMatch}`
  //     );
  //     const data = await response.json();
  //     setPhrases(data["Terme in databasis"]); // Perform search logic here
  //     console.log(`Searching for ${searchTerm} in ${sourceLanguage}`);
  //   };

  //   const handleKeyDown = (event) => {
  //     if (event.key === "Enter") {
  //       handleSearch();
  //     }
  //   };

  // useEffect(() => {
  //   const fetchUserRole = async () => {
  //     if (auth?.accessToken) {
  //       const response = await fetch(baseUrl + 'v1/user-role/', {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${auth.accessToken}`,
  //         },
  //       });
  //       const data = await response.json();
  //       setUserRole(data.role);
  //       console.log("setUserRole in fetchUserRole", data.role)
  //     }
  //   };

  //   fetchUserRole();
  // }, [auth]);

  return (
    <div className="min-h-screen bg-gray-100">
      {/* {auth.userRole === "editor" && <Navbar />}
      {auth.userRole !== "editor" && <Navbaruser />} */}
      {localStorage.setItem("bladsy", "LandingPage")}
      {auth.userName === null && <Navbaranonuser />}
      {auth.userName != null && <Navbar />}

      <div className="flex flex-col justify-center items-center p-2">
        <h1 className="text-4xl font-bold mb-6">
          <Link to={"/"}>
            {/* <a href="/" className="inline-block">  */}
            {t("terminology")}
            {/* </a> */}
          </Link>
        </h1>
        <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg p-3">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder={t("enter")}
            className="border-2 p-2 mb-4 w-full max-w-3xl rounded-lg"
          />

          <div className="mb-4">
            <label
              htmlFor="sourceLanguage"
              className="block text-lg font-medium mb-2"
            >
              {t("source")}
            </label>
            <select
              id="sourceLanguage"
              value={sourceLanguage}
              onChange={(e) => setSourceLanguage(e.target.value)}
              className="w-full p-2 border rounded-lg text-lg md:text-base"
            >
              <option value="Eng">English</option>
              <option value="Afr">Afrikaans</option>
              <option value="Zul">IsiZulu</option>
              <option value="Ses">Sesotho</option>
              <option value="Sep">Sepedi</option>
              <option value="Set">Setswana</option>
              <option value="Xit">XiTsonga</option>
              <option value="Sis">SiSwati</option>
              <option value="Nde">IsiNdebele</option>
              <option value="Xho">IsiXhosa</option>
              <option value="Tsh">TshiVenḓa</option>
              <option value="Por">Portuguese</option>
              <option value="Fre">French</option>
            </select>
          </div>

          <div className="mb-4">
            <label
              htmlFor="targetLanguage"
              className="block text-lg font-medium mb-2"
            >
              {t("target")}
            </label>
            <select
              id="targetLanguage"
              value={targetLanguage}
              onChange={(e) => setTargetLanguage(e.target.value)}
              className="w-full p-2 border rounded-lg"
            >
              <option value="All">{t("alllanguages")}</option>
              <option value="Eng">English</option>
              <option value="Afr">Afrikaans</option>
              <option value="Zul">isiZulu</option>
              <option value="Ses">Sesotho</option>
              <option value="Sep">Sepedi</option>
              <option value="Set">Setswana</option>
              <option value="Xit">XiTsonga</option>
              <option value="Sis">SiSwati</option>
              <option value="Nde">IsiNdebele</option>
              <option value="Xho">IsiXhosa</option>
              <option value="Tsh">TshiVenḓa</option>
              <option value="Por">Portuguese</option>
              <option value="Fre">French</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={partialMatch}
                onChange={() => setPartialMatch(!partialMatch)}
                className="form-checkbox h-5 w-5 text-cyan-700 accent-cyan-700"
              />
              <span className="ml-2 text-lg font-medium">{t("partial")}</span>
            </label>
          </div>

          <button
            onClick={handleSearch}
            className="w-full bg-cyan-700 text-white py-2 px-4 rounded-lg hover:bg-cyan-800"
          >
            {t("search")}
          </button>
          {error && <div className="text-red-900">{error}</div>}
          {isLoading ? (
            <div className="flex items-center justify-center">
              <div
                className="inline-block mt-6 h-8 w-8 animate-spin rounded-full text-cyan-900 border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
            </div>
          ) : (
            //   <div
            //   className="flex items-center justify-center inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
            //   role="status">
            //   <span
            //     className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
            //   >Loading...</span>
            // </div>       // Display the loading indicator while fetching
            // <Atom  color="cyan" segmentWidth={5} />
            <ul>
              <div className="block max-2-96 py-2">
                {phrases.map((searchTerm) => (
                  <li
                    key={searchTerm._id}
                    className="border border-2  rounded  p-2 mb-2"
                  >
                    <Link to={`/phrase/${searchTerm._id}`}>
                      <MathJax>
                        <h3 className="text-xl">{searchTerm.word}</h3>
                        <p>
                          {t("language")}: {languageMapper(searchTerm.language)}
                        </p>
                        <p>
                          {t("context")}: {searchTerm.field}
                        </p>
                      </MathJax>
                    </Link>
                  </li>
                ))}
              </div>
            </ul>
          )}
        </div>
      </div>
      <div className="flex flex-col justify-center items-center p-4">
        <Link to={"/"}>
          <img src={Logo} alt="Logo" width="100" height="100" />
        </Link>
      </div>
      <NoResultsModal
        isOpen={noResults}
        onClose={() => setNoResults(false)}
        searchTerm={searchTerm}
        targetLanguage={targetLanguage}
        sourceLanguage={sourceLanguage}
        gtTerm={gtPhrase}
        onSubmit={handleRequestSubmit}
      />
    </div>
  );
}

export default LandingPage;
