import { jwtDecode } from "jwt-decode";
import { Navigate } from "react-router-dom"

export const baseUrl = 'https://www.navors.com/';
// export const baseUrl = '//10.24.24.244:8000/';
// export const baseUrl = '//192.168.0.104:8000/';

// export const baseUrl = '//10.24.24.244:8000/';



const languageMap = {
    Eng: 'English',
    Afr: 'Afrikaans',
    Zul: 'isiZulu',
    Ses: 'Sesotho',   
    Sep: 'Sepedi',    
    Set: 'Setswana',  
    Xit: 'XiTsonga',  
    Sis: 'SiSwati',   
    Nde: 'IsiNdebele',
    Xho: 'IsiXhosa',  
    Tsh: 'TshiVenḓa', 
    Por: 'Portuguese',
    Fre: 'French'
};

const languageMapper = (shortCode) => {
    return languageMap[shortCode] || shortCode;
};

export default languageMapper;

const statusMap = {
    ACC: 'Accpted',
    PND: 'Pending',
    REJ: 'Rejected',
    FLG: 'Flagged'
};

export const statusMapper = (shortCode) => {
    return statusMap[shortCode] || shortCode;
};

// export const statusMapper;
// src/utils/auth.js

// Helper function to check if the token is expired
export const isTokenExpired = (token) => {
  if (!token) return true;
  const decodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000; // Convert to seconds
  return decodedToken.exp < currentTime;
};

// Function to refresh the access token using the refresh token
export const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) {
    // throw new Error("No refresh token available.");
  return "None";
  }

  const response = await fetch(baseUrl + `v1/token/refresh/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ refresh: refreshToken }),
  });

  if (response.ok) {
    const data = await response.json();
    localStorage.setItem("accessToken", data.access); // Update access token
    return data.access; // Return the new access token
  } else {
    // throw new Error("Failed to refresh token.");
        localStorage.setItem('accessToken',"");
        localStorage.setItem('refreshToken', "");
        localStorage.setItem('username', "");
        localStorage.setItem('userrole', "");
    return <Navigate to='/logout' replace={true} />
  }
};

// Main function to get a valid access token
export const getValidAccessToken = async () => {
  const accessToken = localStorage.getItem("accessToken");
  if (isTokenExpired(accessToken)) {
    try {
      const newAccessToken = await refreshAccessToken();
      if (newAccessToken === "None") {return "None"} else {
      return newAccessToken;}
    } catch (error) {
      console.error("Error refreshing token:", error);
      throw error;
    }
  }

  return accessToken; // Return existing valid token
};
