import React, { createContext, useState } from 'react';

// Create the context
const SharedStateContext = createContext();

// Create a provider component
export const SharedStateProvider = ({ children }) => {
//   const [sharedState, setSharedState] = useState('Initial State');
  const [interfaceLanguage, setInterfaceLanguage] = useState('Eng')
  return (
    <SharedStateContext.Provider value={{ interfaceLanguage, setInterfaceLanguage }}>
      {children}
    </SharedStateContext.Provider>
  );
};

export default SharedStateContext;  
