import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useNavigate } from "react-router-dom";
import raw from "../about.txt";
import { baseUrl } from "../shared";
import SharedStateContext from "../context/sharedstate";
import Navbar from "../navbar";
import Navbaranonuser from "../navbaranonuser";
import { useTranslation } from "react-i18next";
import "../i18n";
import { MathJax } from "better-react-mathjax";
import { AuthContext } from "../context/AuthContext";
import Logo from "../logo512.png";



function About({ isLoggedIn, onLogout }) {
  const { auth } = useContext(AuthContext);

  const { interfaceLanguage, setInterfaceLanguage } =
    useContext(SharedStateContext);
  const [aboutText, setAboutText] = useState("");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetch(raw)
      .then((response) => response.text())
      .then((text) => setAboutText(text));
  }, []);

  console.log("aboutText", aboutText);

  return (
    <div className="min-h-screen bg-gray-100">
      {/* <Navbar /> */}
      {/* {auth.userRole === "editor" && <Navbar />}
      {auth.userRole !== "editor" && <Navbaruser />} */}
      {localStorage.setItem("bladsy","About")}
      {auth.userName === null && <Navbaranonuser />}
      {auth.userName != null && <Navbar />}
      <div className="prose prose-lg p-4 max-w-4xl mx-auto">
        <button
          onClick={() => navigate(-1)}
          className="ml-0 bg-gray-500 text-white py-2 mt-4 mb-0 px-4 rounded-lg hover:bg-gray-600"
        >
          {t("back")}
        </button>
        <MathJax inline dynamic>
          <ReactMarkdown classname="prose">{aboutText}</ReactMarkdown>
        </MathJax>
      </div>
      <div className="flex flex-col justify-center items-center p-4">
        <Link to={"/"}>
          <img src={Logo} alt="Logo" width="100" height="100" />
        </Link>
      </div>
    </div>
  );
}

export default About;
