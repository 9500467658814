import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import AdvancedSearch from "./components/AdvancedSearch";
import PhraseDetail from "./components/PhraseDetail";
import UpdateWord from "./components/UpdateWord";
import TranslateWord from "./components/Translate";
import AddWord from "./components/AddWord";
import AddRequest from "./components/AddRequest";
import Login from "./components/Login";
import Register from "./components/Register";
import Logout from "./components/Logout";
import { AuthProvider } from "./context/AuthContext";
import Logo from "./logo512.png";
import About from "./components/About";
import Contact from "./components/Contact";
import { initReactI18next } from "react-i18next";
import { useTranslation } from "react-i18next";
import "./i18n.js";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import { SharedStateProvider } from "./context/sharedstate";
import ReactModal from "react-modal";
import ListRequests from "./components/ListRequests.js";
import UserRoleManager from "./components/UserRoleManager.js";
import UserLys from "./components/UserLys.js";
import QRCodeGenerator from "./components/QrGenerator.js";
import PasswordResetRequest from './components/PasswordResetRequest';
import PasswordResetForm from './components/PasswordResetForm';
// import PasswordResetRequest from './components/PasswordResetRequest';
// import PasswordResetForm from './components/PasswordResetForm';

// const cors = require("cors");
// App.use(cors());

// import history from './history';
ReactModal.setAppElement("#root");

function App() {
  // const history = useHistory();
  const { t } = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [interfaceLanguage, setInterfaceLanguage] = useState("Eng");

  const mjconfig = {
    loader: { load: ["[tex]/html"] },
    tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"],
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"],
      ],
    },
  };

  // const handleLogout = () => {
  //   setIsLoggedIn(false);
  //   // Perform any additional logout logic
  // };

  return (
    <SharedStateProvider>
      <MathJaxContext  version={3} config={mjconfig}>
        {/* <AuthProvider > */}
        <Router>
          <Routes>
            {/* <Route exact path="/">
          <LandingPage isLoggedIn={isLoggedIn} onLogout={handleLogout} />
        </Route> */}
            <Route path="/" element={<LandingPage />} />
            <Route path="/advanced" element={<AdvancedSearch />} />
            <Route
              path="/update/:id"
              element={<UpdateWord />}
              component={isLoggedIn ? UpdateWord : Login}
            />
            <Route
              path="/translate/:id"
              element={<TranslateWord />}
              component={isLoggedIn ? TranslateWord : Login}
            />
            <Route
              path="/requestedterms"
              element={<ListRequests />}
              component={isLoggedIn ? ListRequests : Login}
            />
            <Route
              path="/addword"
              element={<AddWord />}
              // component={isLoggedIn ? AddWord : Login}
            />
            <Route
              path="/userlys"
              element={<UserLys />}
              component={isLoggedIn ? UserLys : Login}
            />
            <Route
              path="/userroles"
              element={<UserRoleManager />}
              component={isLoggedIn ? UserRoleManager : Login}
            />
            <Route
              path="/addrequest/"
              element={<AddRequest />}
              // component={isLoggedIn ? AddWord : Login}
            />
            <Route path="/phrase/:id" element={<PhraseDetail />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/register" element={<Register />} />
            <Route path="/qr" element={<QRCodeGenerator />} />
            <Route path="/password-reset" element={<PasswordResetRequest />} />
            <Route path="/reset-password/:token" element={<PasswordResetForm />} />
            {/* <Route path="/password-reset" component={PasswordResetRequest} />
            <Route path="/reset-password/:token" component={PasswordResetForm} /> */}
            <Route path="*" element={<div>Page not found</div>} />
          </Routes>
        </Router>
        {/* </AuthProvider> */}
      </MathJaxContext>
    </SharedStateProvider>
  );

  // return (
  //   <AuthProvider>
  //     <Router>
  //       <div className="container mx-auto p-4">
  //         <Routes>
  //           <Route path="/" element={<PhraseList />} />
  //           <Route path="/phrase/:id" element={<PhraseDetail />} />
  //           <Route path="/update/:id" element={<UpdateWord />} />
  //           <Route path="/add" element={<AddWord />} />
  //           <Route path="/login" element={<Login />} />
  //         </Routes>
  //       </div>
  //     </Router>
  //   </AuthProvider>
  // );
}

export default App;
