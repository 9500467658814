import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        word: "Term",
        update_term: "Update term",
        source: "Source language",
        target: "Target language",
        partial: "Partial match",
        enter: "Enter search term",
        terminology: "Terminology Hub",
        alllanguages: "All languages",
        allstatus: "Any status",
        search: "Search",
        language: "Language",
        context: "Context",
        add: "Add a new term",
        advanced: "Advanced search",
        accepted: "Accepted",
        pending: "Pending",
        rejected: "Rejected",
        flagged: "Flagged",
        ACC: "Accepted",
        PND: "Pending",
        REJ: "Rejected",
        FLG: "Flagged",
        status: "Status",
        translate_term: "Translate term",
        sourceword: "Source term",
        translatedterm: "Translated term",
        example: "Example",
        attribution: "Attribution",
        newlanguage: "New language",
        definition: "Definition",
        school: "School",
        about: "About us",
        contact: "Contact us",
        addterm: "Add term",
        termdetail: "Term detail",
        deleteconfirma: "Are you sure you want to delete '",
        deleteconfirmb: "' with context '",
        deleteconfirmc: "' in language '",
        deleteconfirmd: "'?",
        yes: "Yes",
        no: "No",
        back: "Back",
        translate: "Translate",
        update: "Update",
        delete: "Delete",
        cancel: "Cancel",
        login: "Log in",
        logout: "Log out",
        confirmlogout: "Confirm log out",
        register: "Register user",
        emailaddress: "Email address",
        typeagain: "Type email address again",
        surname: "Surname",
        firstname: "First name",
        username: "User name",
        password: "Password",
        message_username: "Username already exists. Please choose another.",
        message_email: "Email already registered. Please use another email.",
        message_valid: "The email address is not valid.",
        message_notsame: "The email addresses are not the same.",
        request_translation: "Request translation",
        submit_request: "Submit request",
        term_not_found: "Term not found",
        submitsuccess: "Request submitted successfully!",
        theterm: "The term ",
        notfound: " was not found.",
        liketorequest: "Would you like to request a translation? ",
        suggest_translationa: "Do you want to suggest a possible term in ",
        suggest_translationb: "?",
        ifsotype: "If so, type it here:",
        notranslation: "No translation was found.",
        add_request: "Add requested term",
        add_translation: "Add translation",
        suggested_translation: "Suggested translation",
        date_requested: "Date requested",
        action: "Action",
        no_requests: "No requests found",
        r_user: "Regular user",
        editor: "Editor",
        administrator: "Administrator",
        requestlist: "Requested terms",
        word_to_be_added: "Term to be added",
        pos: "Part of speech",
        unknown: "Unknown",
        noun: "Noun",
        verb: "Verb",
        adjective: "Adjective",
        pronoun: "Pronoun",
        adverb: "Adverb",
        preposition: "Preposition",
        conjunction: "Conjunction",
        interjection: "Interjection",
        article: "Article",
        suggests: "suggests",
        synonyms: "Synonyms",
        reset_password: "Reset password",
        new_password: "New password",
        confirm_new_password: "Confirm new password",
        send_reset_email: "Send reset email",
        forgot_password: "Forgotten password",
      },
    },
    af: {
      translation: {
        word: "Term",
        update_term: "Dateer inligting op",
        source: "Brontaal",
        target: "Teikentaal",
        partial: "Gedeeltelike passing",
        enter: "Tik soekterm hier in",
        terminology: "Terminologienaaf",
        alllanguages: "Alle tale",
        allstatus: "Enige status",
        search: "Soek",
        language: "Taal",
        context: "Konteks",
        add: "Voeg nuwe term by",
        advanced: "Uitgebreide soektog",
        accepted: "Aanvaar",
        pending: "Onder oorweging",
        ACC: "Aanvaar",
        PND: "Onder oorweging",
        REJ: "Verwerp",
        FLG: "Gemerk",
        rejected: "Verwerp",
        flagged: "Gemerk",
        status: "Status",
        translate_term: "Vertaal term",
        sourceword: "Bronterm",
        translatedterm: "Vertaalde term",
        example: "Voorbeeld",
        attribution: "Erkenning",
        newlanguage: "Nuwe taal",
        definition: "Definisie",
        school: "Skool",
        about: "Meer oor ons",
        contact: "Kontak ons",
        addterm: "Voeg term by",
        termdetail: "Termbesonderhede",
        deleteconfirma: "Is u seker dat u '",
        deleteconfirmb: "' met konteks '",
        deleteconfirmc: "' in taal '",
        deleteconfirmd: "' wil uitvee?",
        yes: "Ja",
        no: "Nee",
        back: "Terug",
        translate: "Vertaal",
        update: "Dateer op",
        delete: "Vee uit",
        cancel: "Kanselleer",
        login: "Teken in",
        logout: "Teken uit",
        confirmlogout: "Bevestig",
        register: "Registreer gebruiker",
        emailaddress: "Epos-adres",
        typeagain: "Tik die epos-adres weer",
        surname: "Van",
        firstname: "Voornaam",
        username: "Gebruikersnaam",
        password: "Wagwoord",
        message_username: "Die gebruikersnaam is nie beskikbaar nie.",
        message_email: "Die epos-adres is reeds geregistreer.",
        message_valid: "Die epos-adres is nie geldig nie.",
        message_notsame: "Die epos-adresse is nie dieselfde nie.",
        request_translation: "Versoek vertaling",
        submit_request: "Dien in",
        term_not_found: "Term nie in databasis nie.",
        submitsuccess: "Die versoek is suksesvol ingedien!",
        theterm: "Die term ",
        notfound: " is nie gevind nie.",
        liketorequest: "Wil u 'n versoek stuur dat 'n vertaling gesoek word?",
        suggest_translationa: "Wil u 'n moontlike vertaling na ",
        suggest_translationb: " voorstel?",
        ifsotype: "Indien wel, tik dit hieronder:",
        notranslation: "Geen vertaling is gevind nie.",
        add_request: "Voeg vertalingsversoek by",
        add_translation: "Vertaal",
        suggested_translation: "Voorgestelde term",
        date_requested: "Datum versoek",
        action: "Aksie",
        no_requests: "Geen versoeke op rekord nie",
        r_user: "Gewone gebruiker",
        editor: "Redakteur",
        administrator: "Administrateur",
        requestlist: "Versoeke",
        word_to_be_added: "Term om by te voeg",
        pos: "Woordsoort",
        unknown: "Onbekend",
        noun: "Selfstandige naamwoord",
        verb: "Werkwoord",
        adjective: "Byvoeglike naamwoord",
        pronoun: "Voornaamwoord",
        adverb: "Bywoord",
        preposition: "Voorsetsel",
        conjunction: "Voegwoord",
        interjection: "Tussenwerpsel",
        article: "Lidwoord",
        suggests: "stel voor",
        synonyms: "Synonieme",
        reset_password: "Wagwoord herstel",
        new_password: "Nuwe wagwoord",
        confirm_new_password: "Bevestig nuwe wagwoord",
        send_reset_email: "Stuur epos om wagwoord te herstel",
        forgot_password: "Vergete wagwoord",
      },
    },
    zu: {
      translation: {
        word: "Izwi",
        update_term: "Buyekeza ithemu",
        source: "Ulimi lomthombo",
        target: "Ulimi oluqondiwe",
        partial: "Ukufana okuncane",
        enter: "Faka igama lokusesha",
        terminology: "Ihabhu yamagama",
        alllanguages: "Zonke izilimi",
        search: "Sesha",
        language: "Ulimi",
        context: "Umongo",
        add: "Engeza ithemu entsha",
        advanced: "Ucwaningo olujulile",
        accepted: "Kwamukelwe",
        pending: "Kusalindile",
        rejected: "Kwaliwe",
        ACC: "Kwamukelwe",
        PND: "Kusalindile",
        REJ: "Kwaliwe",
        status: "Isimo",
        translate_term: "Humusha itemu",
        sourceword: "Igama lomthombo",
        translatedterm: "Itemu elihunyushiwe",
        example: "Isibonelo",
        attribution: "Isichasiso",
        newlanguage: "Ulimi olusha",
        definition: "Incazelo",
        school: "Isikole",
        about: "Mayelana nathi",
        contact: "Xhumana nathi",
        addterm: "Engeza ithemu",
        termdetail: "Imininingwane yethemu",
        deleteconfirma: "Uqinisekile ukuthi ufuna ukususa '",
        deleteconfirmb: "' onomongo'",
        deleteconfirmc: "' ngolimi'",
        deleteconfirmd: "'?",
        yes: "Yebo",
        no: "Cha",
        back: "Emuva",
        translate: "Humusha",
        update: "Buyekeza",
        delete: "Susa",
        cancel: "Ungakwenzi",
        login: "Ngena ngemvume",
        logout: "Phuma ngemvume",
        confirmlogout: "Qinisekisa ukuphuma",
        register: "Bhalisa umsebenzisi",
        emailaddress: "Ikheli le-imeyili",
        typeagain: "Faka ikheli le-imeyili futhi",
        surname: "Isibongo",
        firstname: "Igama",
        username: "Igama lomsebenzisi",
        password: "Iphasiwedi",
        message_username: "Igama lomsebenzisi selivele likhona. Khetha elinye.",
        message_email:
          "I-imeyili isivele ibhalisiwe. Sebenzisa enye i-imeyili.",
        message_valid: "Ikheli le-imeyili alisebenzi.",
        message_notsame: "Amakheli e-imeyili awafani.",
        request_translation: "Cela ukuhumusha",
        submit_request: "Thumela isicelo",
        term_not_found: "Igama alitholakalanga",
        submitsuccess: "Isicelo sithunyelwe ngempumelelo!",
        theterm: "Igama ",
        notfound: " alitholakalanga.",
        liketorequest: "Ungathanda ukufaka isicelo sokuhumusha?",
        suggest_translationa:
          "Ingabe ufuna ukusikisela igama kungenzeka ngesi ",
        suggest_translationb: "?",
        ifsotype: "Uma kunjalo, faka lapha:",
        notranslation: "Ayikho incazelo etholakele.",
        add_request: "Engeza igama eliceliwe",
        add_translation: "Engeza ukuhumusha",
        suggested_translation: "Ukuhumusha okusikiseliwe",
        date_requested: "Usuku olucelwe ngalo",
        action: "Isenzo",
        no_requests: "Awekho amasicelo atholakele",
        r_user: "Umsebenzisi ojwayelekile",
        editor: "Umhleli",
        administrator: "Umphathi",
        requestlist: "Amagama aceliwe",
        word_to_be_added: "Igama okufanele lengezwe",
        pos: "Ingxenye yokukhuluma",
        unknown: "Okungaziwa",
        noun: "Ibizo",
        verb: "Isenzo",
        adjective: "Isiphawulo",
        pronoun: "Isabizwana",
        adverb: "Isandiso",
        preposition: "Isandiso sendawo",
        conjunction: "Isihlanganiso",
        interjection: "Isiphawulo esihlanganisayo",
        article: "Isibabazo",
        suggests: "isikisela",
      },
    },
    st: {
      translation: {
        word: "Lentsoe",
        update_term: "Nchafatso nako",
        source: "Puo ea mohloli",
        target: "Puo e reriloeng",
        partial: "Papali e sa fellang",
        enter: "Kenya lentsoe la ho batla",
        terminology: "Setsi sa Mareo",
        alllanguages: "Lipuo tsohle",
        search: "Batla",
        language: "Puo",
        context: "Moelelo",
        add: "Kenya lentsoe le lecha",
        advanced: "Patlisiso e tsoetseng pele",
        accepted: "E amohetsoe",
        pending: "E emetse",
        rejected: "Hanoa",
        ACC: "E amohetsoe",
        PND: "E emetse",
        REJ: "Hanoa",
        status: "Boemo",
        translate_term: "Lereho la ho fetolela",
        sourceword: "Mohloli nako",
        translatedterm: "Lereho le fetoletsoeng",
        example: "Mohlala",
        attribution: "Tlhahiso",
        newlanguage: "Puo e ncha",
        definition: "Tlhaloso",
        school: "Sekolo",
        about: "Mabapi le rona",
        contact: "Iteanye le rona",
        addterm: "Kenya nako",
        termdetail: "Lintlha tsa nako",
        deleteconfirma: "Na u na le bonnete ba hore u batla ho hlakola '",
        deleteconfirmb: "'le moelelo oa taba'",
        deleteconfirmc: "'ka puo'",
        deleteconfirmd: "'?",
        yes: "Ee",
        no: "Che",
        back: "Morao",
        translate: "Fetolela",
        update: "Nchafatso",
        delete: "Hlakola",
        cancel: "Iphapanyetsa",
        login: "Kena",
        logout: "Tsoa",
        confirmlogout: "Netefatsa ho tsoa",
        register: "Ngodisa mosebedisi",
        emailaddress: "Aterese ya imeile",
        typeagain: "Kenya aterese ya imeile hape",
        surname: "Fane",
        firstname: "Lebitso la pele",
        username: "Lebitso la mosebedisi",
        password: "Password",
        message_username: "Lebitso la mosebedisi le teng. Kgetha le leng.",
        message_email: "Imeile e se e ngodisitswe. Sebelisa imeile e nngwe.",
        message_valid: "Aterese ya imeile ha e nepahe.",
        message_notsame: "Diaterese tsa imeile ha di tshwane.",
        request_translation: "Kopa phetolelo",
        submit_request: "Romela kopo",
        term_not_found: "Lentsoe ha le a fumaneha",
        submitsuccess: "Kopo e rometswe ka katleho!",
        theterm: "Lentsoe ",
        notfound: " ha le a fumaneha.",
        liketorequest: "O rata ho kopa phetolelo?",
        suggest_translationa:
          "O batla ho fana ka tlhahiso ya lentsoe le ka kgonehang ka ",
        suggest_translationb: "?",
        ifsotype: "Ha ho le jwalo, le kenya mona:",
        notranslation: "Ha ho phetolelo e fumanehileng.",
        add_request: "Kenya lentsoe le kopilweng",
        add_translation: "Kenya phetolelo",
        suggested_translation: "Phetolelo e hlalositsweng",
        date_requested: "Letsatsi le kopilweng",
        action: "Ketso",
        no_requests: "Ha ho likopo tse fumanehileng",
        r_user: "Mosebedisi ya tlwaelehileng",
        editor: "Mohlohleli",
        administrator: "Motsamaisi",
        requestlist: "Mantsoe a kopilweng",
        word_to_be_added: "Lentsoe le tla kenngwa",
        pos: "Karolo ya puo",
        unknown: "Ha ho tsejwa",
        noun: "Lebitso",
        verb: "Leetsi",
        adjective: "Lehlalosisi",
        pronoun: "Lebitsokgethi",
        adverb: "Lehlalosisi la leetsi",
        preposition: "Leetsi la boemo",
        conjunction: "Sehlomathiso",
        interjection: "Lentswe la mokgwa",
        article: "Sehlomathiso",
        suggests: "fana ka tlhahiso",
      },
    },
    nso: {
      translation: {
        word: "Lentšu",
        update_term: "Update nako",
        source: "Polelo ya mothopo",
        target: "Polelo ye e nepišitšwego",
        partial: "Papadi ya karolo",
        enter: "Kenya lereo la nyakišišo",
        terminology: "Mareo Hub",
        alllanguages: "Maleme ka moka",
        search: "Nyaka",
        language: "Polelo",
        context: "Kamano",
        add: "Oketša lereo le lefsa",
        advanced: "Patlo ya maemo a godimo",
        accepted: "E amogetšwe",
        pending: "Letetšwe",
        rejected: "E gannwe",
        ACC: "E amogetšwe",
        PND: "Letetšwe",
        REJ: "E gannwe",
        status: "Maemo",
        translate_term: "Fetolela lereo",
        sourceword: "Polelo ya mothopo",
        translatedterm: "Polelo ye e fetoletšwego",
        example: "Mohlala",
        attribution: "Go abela",
        newlanguage: "Polelo ye mpsha",
        definition: "Tlhalošo",
        school: "Sekolo",
        about: "Mabapi le rena",
        contact: "Ikgokaganye le rena",
        addterm: "Oketša lereo",
        termdetail: "Dintlha tša nako",
        deleteconfirma: "Na o na le bonnete bja gore o nyaka go phumola '.",
        deleteconfirmb: "' ka seemo '.",
        deleteconfirmc: "' ka polelo ' .",
        deleteconfirmd: "'?",
        yes: "Ee",
        no: "Aowa",
        back: "Morago",
        translate: "Fetolela",
        update: "Mpshafatša",
        delete: "Phumola",
        cancel: "Hlokomologa",
        login: "Tsena",
        logout: "Tšwa",
        confirmlogout: "Netefatša go tšwa",
        register: "Ngwadiša mošomi",
        emailaddress: "Aderese ya emeile",
        typeagain: "Tsenya aterese ya emeile gape",
        surname: "Sefane",
        firstname: "Leina la pele",
        username: "Leina la mošomi",
        password: "Phasewete",
        message_username:
          "Leina la mošomi le šetše le gona. Hle kgetha le lengwe.",
        message_email:
          "Emeile e šetše e ngwadišitšwe. Hle šomiša emeile e nngwe.",
        message_valid: "Aderese ya emeile ga e nepagale.",
        message_notsame: "Diaderese tša emeile ga di swane.",
        request_translation: "Kopa phetolelo",
        submit_request: "Romela kgopelo",
        term_not_found: "Lentšu ga le hwetšagale",
        submitsuccess: "Kgopelo e rometšwe ka katlego!",
        theterm: "Lentšu ",
        notfound: " ga le hwetšagale.",
        liketorequest: "O nyaka go kopa phetolelo?",
        suggest_translationa: "O nyaka go šišinya lentšu le ka bago ka ",
        suggest_translationb: "?",
        ifsotype: "Ge e le bjalo, tsenya fa:",
        notranslation: "Ga go phetolelo yeo e hwetšwago.",
        add_request: "Tsenya lentšu le kgopetšwego",
        add_translation: "Tsenya phetolelo",
        suggested_translation: "Phetolelo ye e šišintšwego",
        date_requested: "Letšatši le kgopetšwego",
        action: "Kgato",
        no_requests: "Ga go kgopelo yeo e hwetšwago",
        r_user: "Mošomi wa ka mehla",
        editor: "Mohlopholli",
        administrator: "Mohlokomedi",
        requestlist: "Mantšu a kgopetšwego",
        word_to_be_added: "Lentšu leo le swanetšego go tsenywa",
        pos: "Karolo ya polelo",
        unknown: "Ga go tsebege",
        noun: "Leina",
        verb: "Leetsi",
        adjective: "Lehlalosi",
        pronoun: "Leina le thopago",
        adverb: "Lehlalosetsi",
        preposition: "Lehlalosi la maemo",
        conjunction: "Sehlamela",
        interjection: "Lehlalosabjale",
        article: "Lentšu le lekanyago",
        suggests: "šišinya",
      },
    },
    tn: {
      translation: {
        word: "Lefoko",
        update_term: "Ntšhwafatsa paka",
        source: "Puo ya motswedi",
        target: "Puo e e ikaeletsweng",
        partial: "Bontlhanngwe jwa tshwano",
        enter: "Tsenya lereo la patlo",
        terminology: "Lefelo la Mareo",
        alllanguages: "Dipuo tsotlhe",
        search: "Phuruphutsa",
        language: "Puo",
        context: "Bokao",
        add: "Tsenya lereo le lešwa",
        advanced: "Patlo e e tseneletseng",
        accepted: "E amogetswe",
        pending: "Letetse",
        rejected: "E gannwe",
        ACC: "E amogetswe",
        PND: "Letetse",
        REJ: "E gannwe",
        status: "Seemo",
        translate_term: "Ranola lereo",
        sourceword: "Lereo la motswedi",
        translatedterm: "Lereo le le ranotsweng",
        example: "Sekai",
        attribution: "Tlhaloso",
        newlanguage: "Puo e ntšhwa",
        definition: "Tlhaloso",
        school: "Sekolo",
        about: "Ka ga rona",
        contact: "Ikgolaganye le rona",
        addterm: "Tsenya lereo",
        termdetail: "Dintlha tsa paka",
        deleteconfirma: "A o tlhomamisa gore o batla go phimola '",
        deleteconfirmb: "' ka bokao '",
        deleteconfirmc: "' ka puo '",
        deleteconfirmd: "'?",
        yes: "ee",
        no: "nnyaa",
        back: "Morago",
        translate: "Fetolela",
        update: "Tlhabolola",
        delete: "Phimola",
        cancel: "Khansela",
        login: "Tsenya",
        logout: "Tswala",
        confirmlogout: "Netefatsa go tswa",
        register: "Ngodisa mosomi",
        emailaddress: "Aterese ya emeile",
        typeagain: "Tsenya aterese ya emeile gape",
        surname: "Sefane",
        firstname: "Leina la ntlha",
        username: "Leina la mosomi",
        password: "Password",
        message_username: "Leina la mosomi le teng. Kgetha le lengwe.",
        message_email: "Emeile e setse e ngodisiwe. Dira ka e nngwe.",
        message_valid: "Aterese ya emeile ga e a siama.",
        message_notsame: "Diaterese tsa emeile ga di tshwane.",
        request_translation: "Kopa phetolelo",
        submit_request: "Romela kopo",
        term_not_found: "Lefoko ga le a fitlhelwa",
        submitsuccess: "Kopo e rometswe ka katlego!",
        theterm: "Lefoko ",
        notfound: " ga le a fitlhelwa.",
        liketorequest: "A o rata go kopa phetolelo?",
        suggest_translationa:
          "A o batla go naya tlhahiso ya lefoko le le ka kgonegang ka ",
        suggest_translationb: "?",
        ifsotype: "Fa go le jalo, tsenya mo go:",
        notranslation: "Ga go na phetolelo e e fitlhelwang.",
        add_request: "Tsenya lefoko le le kopiwang",
        add_translation: "Tsenya phetolelo",
        suggested_translation: "Phetolelo e e tshitshinnyweng",
        date_requested: "Letsatsi le kopiwang ka lona",
        action: "Tiro",
        no_requests: "Ga go na dikopo tse di fitlhelwang",
        r_user: "Mosomi wa tlwaelo",
        editor: "Mohlophisi",
        administrator: "Mokhanselara",
        requestlist: "Mafoko a a kopiwang",
        word_to_be_added: "Lefoko le le ka tsenywang",
        pos: "Karolo ya puo",
        unknown: "Ga go itsiwe",
        noun: "Leina",
        verb: "Leetsi",
        adjective: "Lehlalosetsi",
        pronoun: "Leina le le kgethegileng",
        adverb: "Lehlalosetsi la leetsi",
        preposition: "Leina le tshwantshang maemo",
        conjunction: "Seamodimo",
        interjection: "Lehlalosabjale",
        article: "Selalelo",
        suggests: "tshitshinya",
      },
    },
    ts: {
      translation: {
        word: "Rito",
        update_term: "Nkarhi wo pfuxeta",
        source: "Ririmi ra xihlovo",
        target: "Ririmi leri kongomisiweke",
        partial: "Ku fambisana ka xiphemu",
        enter: "Nghenisa rito ro lavisisa",
        terminology: "Hub ya Theminoloji",
        alllanguages: "Tindzimi hinkwato",
        search: "Secha",
        language: "Ririmi",
        context: "Vundzeni",
        add: "Engetela rito lerintshwa",
        advanced: "Ku lavisisa loku yaka emahlweni",
        accepted: "Ku amukeriwile",
        pending: "Rindzerile",
        rejected: "Ku ariwile",
        ACC: "Ku amukeriwile",
        PND: "Rindzerile",
        REJ: "Ku ariwile",
        status: "Xiyimo",
        translate_term: "Hundzuluxa rito",
        sourceword: "Rito ra xihlovo",
        translatedterm: "Rito leri hundzuluxeriweke",
        example: "Xikombiso",
        attribution: "Ku nyika xihlawuhlawu",
        newlanguage: "Ririmi lerintshwa",
        definition: "Nhlamuselo",
        school: "Xikolo",
        about: "Malunghana na hina",
        contact: "Tihlanganise na hina",
        addterm: "Engetelani rito",
        termdetail: "Vuxokoxoko bya theme",
        deleteconfirma: "Xana u tiyisile leswaku u lava ku susa '.",
        deleteconfirmb: "' na mongo ' .",
        deleteconfirmc: "' hi ririmi ' .",
        deleteconfirmd: "'?",
        yes: "Ina",
        no: "E-e",
        back: "Tlhelela",
        translate: "Hundzuluxa",
        update: "Pfuxeta",
        delete: "Sula",
        cancel: "Khansela",
        login: "Nghena",
        logout: "Huma",
        confirmlogout: "Tiyisa ku huma",
        register: "Tsarisa muchayeri",
        emailaddress: "Adirese ya imeyili",
        typeagain: "Tlanga adirese ya imeyili nakambe",
        surname: "Xivongo",
        firstname: "Vito ro sungula",
        username: "Vito ra muchayeri",
        password: "Phaswedi",
        message_username: "Vito ra muchayeri ri kona. Hlawula rin’wana.",
        message_email: "Imeriyili yi tsarisiwile. Tirhisa lembe rin’wana.",
        message_valid: "Adirese ya imeyili a yi lulamanga.",
        message_notsame: "Tiadirese ta imeyili a ti fani.",
        request_translation: "Komba ku hundzuluxiwa",
        submit_request: "Tshika xikombelo",
        term_not_found: "Rito a ri kumekanga",
        submitsuccess: "Xikombelo xi tshikiwile hi ku humelela!",
        theterm: "Rito ",
        notfound: " a ri kumekanga.",
        liketorequest: "Xana u lava ku kombela ku hundzuluxiwa?",
        suggest_translationa:
          "Xana u lava ku nyikela rito leri nga tolovelekangiki hi ",
        suggest_translationb: "?",
        ifsotype: "Loko swi ri tano, nghenisa laha:",
        notranslation: "A ku kumekangi ku hundzuluxiwa.",
        add_request: "Engetela rito leri kombiwaka",
        add_translation: "Engetela ku hundzuluxiwa",
        suggested_translation: "Ku hundzuluxiwa loku tshihiweke",
        date_requested: "Siku leri kombiweke",
        action: "Ntirho",
        no_requests: "A ku na swikombelo leswi kumekaka",
        r_user: "Muchayeri wa nkarhi hinkwawo",
        editor: "Muhundzuluxi",
        administrator: "Mulawuri",
        requestlist: "Marito lawa kombiwaka",
        word_to_be_added: "Rito leri fanele ku engeteleriwa",
        pos: "Xiphemu xa ririmi",
        unknown: "A ku tiviwi",
        noun: "Vito",
        verb: "Rito ra ku endla",
        adjective: "Xiyila rito",
        pronoun: "Rito ro hlawula",
        adverb: "Xiyila rito ra ku endla",
        preposition: "Xiyila rito ra ndhawu",
        conjunction: "Xiyila rito ro hlanganisa",
        interjection: "Rito ra ku tshikilerisa",
        article: "Xiyila rito",
        suggests: "tshikisa",
      },
    },
    ss: {
      translation: {
        word: "Livi",
        update_term: "Vuselela sikhatsi",
        source: "Lulwimi lwemtfombo",
        target: "Lulwimi loluhlosiwe",
        partial: "Umdlalo loyincenye",
        enter: "Faka ligama lekuphenya",
        terminology: "Sikhungo Semagama",
        alllanguages: "Tonkhe tilwimi",
        search: "Cinga",
        language: "Lulimi",
        context: "Umongo",
        add: "Ngeta ligama lelisha",
        advanced: "Luphenyo lolutfutfukile",
        accepted: "Kwemukelwe",
        pending: "Lokulindzelwe",
        rejected: "Kulahliwe",
        ACC: "Kwemukelwe",
        PND: "Lokulindzelwe",
        REJ: "Kulahliwe",
        status: "Sikhundla",
        translate_term: "Humusha ligama",
        sourceword: "Ligama lemtfombo",
        translatedterm: "Ligama lelihunyushiwe",
        example: "Sibonelo",
        attribution: "Kuniketwa",
        newlanguage: "Lulwimi lolusha",
        definition: "Incazelo",
        school: "Sikolwa",
        about: "Ngatsi",
        contact: "Xhumana natsi",
        addterm: "Ngeta ligama",
        termdetail: "Imininingwane yethemu",
        deleteconfirma: "Ucinisekile yini kutsi ufuna kucisha '",
        deleteconfirmb: "' ngesimo '",
        deleteconfirmc: "' ngelulwimi '",
        deleteconfirmd: "'?",
        yes: "Yebo",
        no: "Cha",
        back: "Ngemuva",
        translate: "Humusha",
        update: "Lungisa",
        delete: "Ususe",
        cancel: "Ungakwenti",
        login: "Ngena",
        logout: "Phuma",
        confirmlogout: "Qinisekisa ukuphuma",
        register: "Bhalisa umsebenzisi",
        emailaddress: "Ikheli le-imeyili",
        typeagain: "Faka ikheli le-imeyili futsi",
        surname: "Sibongo",
        firstname: "Ligama lokucala",
        username: "Ligama lomsebenzisi",
        password: "Iphasiwedi",
        message_username:
          "Ligama lomsebenzisi selivele likhona. Khetha lelinye.",
        message_email: "I-imeyili isivele ibhalisiwe. Sebentisa lelinye.",
        message_valid: "Ikheli le-imeyili alilungile.",
        message_notsame: "Tikhala le-imeyili atifani.",
        request_translation: "Cela kuhumusha",
        submit_request: "Faka sicelo",
        term_not_found: "Livi alikatalwa",
        submitsuccess: "Sicelo sifakwe ngempumelelo!",
        theterm: "Livi ",
        notfound: " alikatalwa.",
        liketorequest: "Ungatsandza kucela kuhumusha?",
        suggest_translationa:
          "Utsandza yini kuphakamisa livi lelingaba khona ngesi ",
        suggest_translationb: "?",
        ifsotype: "Nangabe kunjalo, faka lapha:",
        notranslation: "Akukatalwa kuhumusha.",
        add_request: "Engeta livi lecelo",
        add_translation: "Engeta kuhumusha",
        suggested_translation: "Kuhumusha lokuphakanyisiwe",
        date_requested: "Lilanga leceliwe ngalo",
        action: "Sisebenti",
        no_requests: "Akukatalwa sicelo",
        r_user: "Umsebenzisi lomtali",
        editor: "Umhleli",
        administrator: "Mphatsi",
        requestlist: "Magama laceliwe",
        word_to_be_added: "Livi lelitawungeziwe",
        pos: "Ingxenye yesigaba solwimi",
        unknown: "Akwatiwa",
        noun: "Libito",
        verb: "Senzamndla",
        adjective: "Isiphawulo",
        pronoun: "Libito lesikhulumi",
        adverb: "Isandziso",
        preposition: "Isikhundla",
        conjunction: "Lihlanganisi",
        interjection: "Kuphakanyiso",
        article: "Isihloko",
        suggests: "uphakamisa",
      },
    },
    nr: {
      translation: {
        word: "Ilizwi",
        update_term: "Buyisela ilizwi",
        source: "Ulwimi lwemvelaphi",
        target: "Ulwimi okuya kulo",
        partial: "Ukufana ngesigaba",
        enter: "Faka ilizwi lokucwaninga",
        terminology: "Isikhungo Samagama",
        alllanguages: "Zonke izilimi",
        allstatus: "Zonke izimo",
        search: "Cwaninga",
        language: "Ulwimi",
        context: "Umsuka",
        add: "Engeza ilizwi elitsha",
        advanced: "Ukucwaninga okujulile",
        accepted: "Kuvunyiwe",
        pending: "Kulindwe",
        rejected: "Kwaliwe",
        flagged: "Kuphawuliwe",
        ACC: "Kuvunyiwe",
        PND: "Kulindwe",
        REJ: "Kwaliwe",
        FLG: "Kuphawuliwe",
        status: "Isimo",
        translate_term: "Humusha ilizwi",
        sourceword: "Ilizwi lomthombo",
        translatedterm: "Ilizwi elihunyushwe",
        example: "Isibonelo",
        attribution: "Ukubongwa",
        newlanguage: "Ulwimi olusha",
        definition: "Incazelo",
        school: "Isikolo",
        about: "Ngathi",
        contact: "Thintana nathi",
        addterm: "Engeza ilizwi",
        termdetail: "Imininingwane yelizwi",
        deleteconfirma: "Uqinisekile ukuthi ufuna ukususa '",
        deleteconfirmb: "' ngekonteksti '",
        deleteconfirmc: "' ngolimi lwe '",
        deleteconfirmd: "'?",
        yes: "Yebo",
        no: "Hayi",
        back: "Buyela emuva",
        translate: "Humusha",
        update: "Buyisela",
        delete: "Susa",
        cancel: "Khansela",
        login: "Ngena",
        logout: "Phuma",
        confirmlogout: "Qinisekisa ukuphuma",
        register: "Bhalisa umsebenzisi",
        emailaddress: "Ikheli le-imeyili",
        typeagain: "Faka ikheli le-imeyili futhi",
        surname: "Isibongo",
        firstname: "Igama lokuqala",
        username: "Igama lomsebenzisi",
        password: "Iphasiwedi",
        message_username: "Igama lomsebenzisi selikhona. Khetha elinye.",
        message_email: "I-imeyili isivele ibhalisiwe. Sebenzisa enye.",
        message_valid: "Ikheli le-imeyili alilungile.",
        message_notsame: "Amakheli e-imeyili awafani.",
        request_translation: "Cela ukuhumusha",
        submit_request: "Faka isicelo",
        term_not_found: "Ilizwi alitholakalanga",
        submitsuccess: "Isicelo sifakwe ngempumelelo!",
        theterm: "Ilizwi ",
        notfound: " alitholakalanga.",
        liketorequest: "Ungathanda ukucela ukuhumusha?",
        suggest_translationa: "Ufuna ukuphakamisela elinye ilizwi ngesi ",
        suggest_translationb: "?",
        ifsotype: "Uma kunjalo, faka lapha:",
        notranslation: "Awekho amagama ahunyushwe atholakele.",
        add_request: "Engeza ilizwi eliceliwe",
        add_translation: "Engeza ukuhumusha",
        suggested_translation: "Ukuhumusha okuphakanyisiwe",
        date_requested: "Usuku lokucela",
        action: "Isenzo",
        no_requests: "Awekho amacala atholakele",
        r_user: "Umsebenzisi ojwayelekile",
        editor: "Umhleli",
        administrator: "Umphathi",
        requestlist: "Amagama aceliwe",
        word_to_be_added: "Ilizwi elizongezwa",
        pos: "Ingxenye yolimi",
        unknown: "Akukaziwa",
        noun: "Ibizo",
        verb: "Isenzo",
        adjective: "Isiphawulo",
        pronoun: "Isabizwana",
        adverb: "Isandiso",
        preposition: "Isandiso sendawo",
        conjunction: "Isihlanganiso",
        interjection: "Izibonakaliso",
        article: "Isihloko",
        suggests: "uphakamisela",
      },
    },
    xh: {
      translation: {
        word: "Ilizwi",
        update_term: "Hlaziya ixesha",
        source: "Ulwimi lwentsusa",
        target: "Ulwimi ekujoliswe kulo",
        partial: "Umdlalo ongaphelelanga",
        enter: "Ngenisa igama lokukhangela",
        terminology: "IZiko lesigama",
        alllanguages: "Zonke iilwimi",
        search: "Khangela",
        language: "Ulwimi",
        context: "Umxholo",
        add: "Yongeza igama elitsha",
        advanced: "Ukhangelo olunzulu",
        accepted: "Yamkelwe",
        pending: "Ilindile",
        rejected: "Yaliwe",
        ACC: "Yamkelwe",
        PND: "Ilindile",
        REJ: "Yaliwe",
        status: "Ubume",
        translate_term: "Guqulela ixesha",
        sourceword: "Ixesha lomthombo",
        translatedterm: "Ixesha eliguqulelweyo",
        example: "Umzekelo",
        attribution: "Unikezelo",
        newlanguage: "Ulwimi olutsha",
        definition: "Ingcaciso",
        school: "Isikolo",
        about: "Ngathi",
        contact: "Qhagamshelana nathi",
        addterm: "Yongeza ixesha",
        termdetail: "Iinkcukacha zexesha",
        deleteconfirma: "Uqinisekile ufuna ukucima '",
        deleteconfirmb: "' ngomxholo'",
        deleteconfirmc: "' ngolwimi'",
        deleteconfirmd: "'?",
        yes: "Ewe",
        no: "Hayi",
        back: "Emva",
        translate: "Guqulela",
        update: "Hlaziya",
        delete: "Cima",
        cancel: "Rhoxisa",
        login: "Ngena",
        logout: "Phuma",
        confirmlogout: "Qinisekisa ukuphuma",
        register: "Bhalisa umsebenzisi",
        emailaddress: "Idilesi ye-imeyili",
        typeagain: "Faka kwakhona idilesi ye-imeyili",
        surname: "Ifani",
        firstname: "Igama lokuqala",
        username: "Igama lomsebenzisi",
        password: "Iphasiwedi",
        message_username: "Igama lomsebenzisi selikhona. Khetha elinye.",
        message_email: "Le imeyili sele ibhalisiwe. Sebenzisa enye.",
        message_valid: "Idilesi ye-imeyili ayilunganga.",
        message_notsame: "Iidilesi ze-imeyili azifani.",
        request_translation: "Cela uguqulelo",
        submit_request: "Ngenisa isicelo",
        term_not_found: "Igama alifumanekanga",
        submitsuccess: "Isicelo singeniswe ngempumelelo!",
        theterm: "Igama ",
        notfound: " alifumanekanga.",
        liketorequest: "Ungathanda ukucela uguqulelo?",
        suggest_translationa: "Ngaba ungathanda ukuphakamisa igama elinokubakho ngesi ",
        suggest_translationb: "?",
        ifsotype: "Ukuba kunjalo, ngenisa apha:",
        notranslation: "Akukho guqulelo lifunyenweyo.",
        add_request: "Yongeza igama elicelwayo",
        add_translation: "Yongeza uguqulelo",
        suggested_translation: "Uguqulelo oluphakanyisiweyo",
        date_requested: "Umhla oceliwe",
        action: "Isenzo",
        no_requests: "Akukho zicelo zifunyenweyo",
        r_user: "Umsebenzisi oqhelekileyo",
        editor: "Umhleli",
        administrator: "Umphathi",
        requestlist: "Amagama acelwayo",
        word_to_be_added: 'Igama eliza kongezwa',
        pos: "Inxalenye yolwimi",
        unknown: "Akukaziwa",
        noun: "Isibizo",
        verb: "Isenzi",
        adjective: "Isiphawuli",
        pronoun: "Isabizwana",
        adverb: "Isihlomelo",
        preposition: "Isihlanganisi sendawo",
        conjunction: "Isihlanganisi",
        interjection: "Isimamezelo",
        article: "Inqaku",
        suggests: "uphakamisa",
      },
    },
    ve: {
      translation: {
        word: "Ipfi",
        update_term: "Themo ya u khwinisa",
        source: "Luambo lwa tshisima",
        target: "Luambo lwo livhiswaho",
        partial: "Mutambo wa tshipiḓa",
        enter: "Ṅwalani themo ya ṱhoḓisiso",
        terminology: "Hub ya Theminolodzhi",
        alllanguages: "Nyambo dzothe .",
        search: "Ṱoḓa",
        language: "Luambo",
        context: "Tsiangane",
        add: "Kha vha engedze themo ntswa",
        advanced: "U ṱoḓa ha nṱhesa",
        accepted: "Zwo ṱanganedzwa",
        pending: "Shumisa",
        rejected: "Ho landulwa",
        ACC: "Zwo ṱanganedzwa",
        PND: "Shumisa",
        REJ: "Ho landulwa",
        status: "Tshiimo",
        translate_term: "Kha vha ṱalutshedzele themo",
        sourceword: "Themo ya tshisima",
        translatedterm: "Themo yo ṱalutshedzelwaho",
        example: "Tsumbo",
        attribution: "U ṋea vhuṱanzi",
        newlanguage: "Luambo luswa .",
        definition: "Ṱhalutshedzo",
        school: "Tshikolo",
        about: "Nga ha rine .",
        contact: "Kha vha ri kwame",
        addterm: "Engedza themo",
        termdetail: "Zwidodombedzwa zwa themo",
        deleteconfirma: "Ni na vhutanzi uri ni khou toda u phumula ' .",
        deleteconfirmb: "' na vhubvo '",
        deleteconfirmc: "' kha luambo ' .",
        deleteconfirmd: "'?",
        yes: "Ee",
        no: "Hai",
        back: "Murahu",
        translate: "Phindulela",
        update: "Khwiniso",
        delete: "Thutha",
        cancel: "Khansela",
        login: "Ngena",
        logout: "Phuma",
        confirmlogout: "Qinisekisa ukuphuma",
        register: "Bhalisa umsebenzisi",
        emailaddress: "Idilesi ye-imeyili",
        typeagain: "Faka kwakhona idilesi ye-imeyili",
        surname: "Ifani",
        firstname: "Igama lokuqala",
        username: "Igama lomsebenzisi",
        password: "Iphasiwedi",
        message_username: "Igama lomsebenzisi selikhona. Khetha elinye.",
        message_email: "I-imeyili isivele ibhalisiwe. Sebenzisa enye.",
        message_valid: "Idilesi ye-imeyili ayilunganga.",
        message_notsame: "Iidilesi ze-imeyili azifani.",
        request_translation: "Cela uguqulelo",
        submit_request: "Ngenisa isicelo",
        term_not_found: "Nḓivho i sa funi",
        submitsuccess: "Isicelo singeniswe ngempumelelo!",
        theterm: "Nḓivho ",
        notfound: " i sa funi.",
        liketorequest: "Ungathanda ukucela uguqulelo?",
        suggest_translationa: "Ngaba ungathanda ukuphakamisa nḓivho elinokubakho ngesi ",
        suggest_translationb: "?",
        ifsotype: "Ukuba kunjalo, ngenisa apha:",
        notranslation: "Awekho amagama ahunyushwe atholakele.",
        add_request: "Yongeza nḓivho elicelwayo",
        add_translation: "Yongeza uguqulelo",
        suggested_translation: "Uguqulelo oluphakanyisiweyo",
        date_requested: "Usuku lokucela",
        action: "Isenzo",
        no_requests: "Awekho amacala atholakele",
        r_user: "Umsebenzisi ojwayelekile",
        editor: "Umhleli",
        administrator: "Umphathi",
        requestlist: "Amagama aceliwe",
        word_to_be_added: 'Nḓivho i ḓo nyadzwa',
        pos: "Ingxenye yolwimi",
        unknown: "Akwatiwa",
        noun: "Ibizo",
        verb: "Isenzo",
        adjective: "Isiphawulo",
        pronoun: "Isabizwana",
        adverb: "Isandiso",
        preposition: "Isikhundla",
        conjunction: "Lihlanganisi",
        interjection: "Kuphakanyiso",
        article: "Isihloko",
        suggests: "uphakamisa",
      },
    },
    pt: {
      translation: {
        word: "Termo",
        update_term: "Prazo de atualização",
        source: "Idioma de origem",
        target: "Idioma alvo",
        partial: "Correspondência parcial",
        enter: "Insira o termo de pesquisa",
        terminology: "Centro de Terminologia",
        alllanguages: "Todos os idiomas",
        search: "Procurar",
        language: "Linguagem",
        context: "Contexto",
        add: "Adicionar um novo termo",
        advanced: "Pesquisa avançada",
        accepted: "Aceito",
        pending: "Pendente",
        rejected: "Rejeitado",
        ACC: "Aceito",
        PND: "Pendente",
        REJ: "Rejeitado",
        status: "Status",
        translate_term: "Traduzir termo",
        sourceword: "Termo de origem",
        translatedterm: "Termo traduzido",
        example: "Exemplo",
        attribution: "Atribuição",
        newlanguage: "Novo idioma",
        definition: "Definição",
        school: "Escola",
        about: "Sobre nós",
        contact: "Contate-nos",
        addterm: "Adicionar termo",
        termdetail: "Detalhe do prazo",
        deleteconfirma: "Tem certeza de que deseja excluir '",
        deleteconfirmb: "'com contexto'",
        deleteconfirmc: "'na linguagem'",
        deleteconfirmd: "'?",
        yes: "Sim",
        no: "Não",
        back: "Voltar",
        translate: "Traduzir",
        update: "Atualizar",
        delete: "Excluir",
        cancel: "Cancelar",
        login: "Entrar",
        logout: "Sair",
        confirmlogout: "Confirmar saída",
        register: "Registrar usuário",
        emailaddress: "Endereço de e-mail",
        typeagain: "Digite o endereço de e-mail novamente",
        surname: "Sobrenome",
        firstname: "Nome",
        username: "Nome de usuário",
        password: "Senha",
        message_username: "Nome de usuário já existe. Por favor, escolha outro.",
        message_email: "E-mail já registrado. Por favor, use outro e-mail.",
        message_valid: "O endereço de e-mail não é válido.",
        message_notsame: "Os endereços de e-mail não são os mesmos.",
        request_translation: "Solicitar tradução",
        submit_request: "Enviar solicitação",
        term_not_found: "Termo não encontrado",
        submitsuccess: "Solicitação enviada com sucesso!",
        theterm: "O termo ",
        notfound: " não foi encontrado.",
        liketorequest: "Você gostaria de solicitar uma tradução? ",
        suggest_translationa: "Você quer sugerir um termo possível em ",
        suggest_translationb: "?",
        ifsotype: "Se sim, digite aqui:",
        notranslation: "Nenhuma tradução foi encontrada.",
        add_request: "Adicionar termo solicitado",
        add_translation: "Adicionar tradução",
        suggested_translation: "Tradução sugerida",
        date_requested: "Data solicitada",
        action: "Ação",
        no_requests: "Nenhum pedido encontrado",
        r_user: "Usuário regular",
        editor: "Editor",
        administrator: "Administrador",
        requestlist: "Termos solicitados",
        word_to_be_added: 'Termo a ser adicionado',
        pos: "Parte do discurso",
        unknown: "Desconhecido",
        noun: "Substantivo",
        verb: "Verbo",
        adjective: "Adjetivo",
        pronoun: "Pronome",
        adverb: "Advérbio",
        preposition: "Preposição",
        conjunction: "Conjunção",
        interjection: "Interjeição",
        article: "Artigo",
        suggests: "sugere",
      },
    },
    fr: {
      translation: {
        word: "Terme",
        update_term: "Terme de mise à jour",
        source: "Langue source",
        target: "Langue cible",
        partial: "Correspondance partielle",
        enter: "Entrez le terme de recherche",
        terminology: "Centre de terminologie",
        alllanguages: "Toutes les langues",
        search: "Recherche",
        language: "Langue",
        context: "Contexte",
        add: "Ajouter un nouveau terme",
        advanced: "Recherche avancée",
        accepted: "Accepté",
        pending: "En attente",
        rejected: "Rejeté",
        ACC: "Accepté",
        PND: "En attente",
        REJ: "Rejeté",
        status: "Statut",
        translate_term: "Traduire le terme",
        sourceword: "Terme source",
        translatedterm: "Terme traduit",
        example: "Exemple",
        attribution: "Attribution",
        newlanguage: "Nouvelle langue",
        definition: "Définition",
        school: "École",
        about: "À propos de nous",
        contact: "Contactez-nous",
        addterm: "Ajouter un terme",
        termdetail: "Détail du terme",
        deleteconfirma: "Etes-vous sûr de vouloir supprimer '",
        deleteconfirmb: "'avec contexte'",
        deleteconfirmc: "'dans la langue'",
        deleteconfirmd: "'?",
        yes: "Oui",
        no: "Non",
        back: "Dos",
        translate: "Traduire",
        update: "Mise à jour",
        delete: "Supprimer",
        cancel: "Annuler",
        login: "Se connecter",
        logout: "Se déconnecter",
        confirmlogout: "Confirmer la déconnexion",
        register: "S'inscrire",
        emailaddress: "Adresse e-mail",
        typeagain: "Ressaisissez l'adresse e-mail",
        surname: "Nom de famille",
        firstname: "Prénom",
        username: "Nom d'utilisateur",
        password: "Mot de passe",
        message_username: "Le nom d'utilisateur existe déjà. Veuillez en choisir un autre.",
        message_email: "L'e-mail est déjà enregistré. Veuillez utiliser un autre e-mail.",
        message_valid: "L'adresse e-mail n'est pas valide.",
        message_notsame: "Les adresses e-mail ne sont pas les mêmes.",
        request_translation: "Demander une traduction",
        submit_request: "Soumettre la demande",
        term_not_found: "Terme non trouvé",
        submitsuccess: "Demande soumise avec succès!",
        theterm: "Le terme ",
        notfound: " n'a pas été trouvé.",
        liketorequest: "Voulez-vous demander une traduction? ",
        suggest_translationa: "Voulez-vous suggérer un terme possible en ",
        suggest_translationb: "?",
        ifsotype: "Si oui, tapez-le ici:",
        notranslation: "Aucune traduction trouvée.",
        add_request: "Ajouter le terme demandé",
        add_translation: "Ajouter une traduction",
        suggested_translation: "Traduction suggérée",
        date_requested: "Date demandée",
        action: "Action",
        no_requests: "Aucune demande trouvée",
        r_user: "Utilisateur régulier",
        editor: "Éditeur",
        administrator: "Administrateur",
        requestlist: "Termes demandés",
        word_to_be_added: 'Terme à ajouter',
        pos: "Partie du discours",
        unknown: "Inconnu",
        noun: "Nom",
        verb: "Verbe",
        adjective: "Adjectif",
        pronoun: "Pronom",
        adverb: "Adverbe",
        preposition: "Préposition",
        conjunction: "Conjonction",
        interjection: "Interjection",
        article: "Article",
        suggests: "suggère",
      },
    },
  },
});

export default i18n;
