import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import languageMapper from "../shared";
import Logo from "../logo512.png";
import { useTranslation } from "react-i18next";
import "../i18n";
import { create } from "zustand";
import { MathJax } from "better-react-mathjax";
import { baseUrl } from "../shared";
import SharedStateContext from "../context/sharedstate";
import Navbar from "../navbar";
import Navbaranonuser from "../navbaranonuser";

function Logout() {
    const { logout } = useContext(AuthContext);
  const { auth } = useContext(AuthContext); // Access the auth state
  // const { login } = useContext(AuthContext);
  // const [username, setUsername] = useState("");
  // const [password, setPassword] = useState("");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const response = await fetch(baseUrl + "v1/login/", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({ username, password }),
  //   });
  //   const data = await response.json();
  //   if (response.ok) {
  //     login(data.access, data.refresh, data.username, data.role);
  //   //   localStorage.setItem('access', data.access);
  //   //   localStorage.setItem('refresh', data.refresh);
  //   //   localStorage.setItem('username', data.username);
  //   //   localStorage.setItem('userrole', data.role);
  //     console.log(
  //       "data: ",
  //       data.username,
  //       data.role,
  //       data.access,
  //       data.refresh
  //     );
  //     console.log("Sukses: ", username);
  //     console.log("auth.accessToken: vanuit Login", auth.accessToken);
  //     navigate(-1)
  //   } else {
  //     console.error("Login failed");
  //   }
  // };


  
  // const handleKeyDown = (event) => {
  //   if (event.key === "Enter") {
  //     handleSubmit(event);
  //   }
  // };

  // useEffect(() => {
  //   const fetchUserRole = async () => {
  //     if (auth?.accessToken) {
  //       const response = await fetch(baseUrl + 'v1/user-role/', {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${auth.accessToken}`,
  //         },
  //       });
  //       const data = await response.json();
  //       setUserRole(data.role);
  //       console.log("setUserRole in fetchUserRole", data.role)
  //     }
  //   };

  //   fetchUserRole();
  // }, [auth]);
  useEffect((e) => {
    if (auth?.accessToken) {} else {
        navigate(-2) ;
      } 
  }, []);

  return (
    <div className="min-h-screen bg-gray-100">
      {/* <Navbar /> */}
      {/* <div> */}
      {/* {auth.userRole === "editor" && <Navbar />}
      {auth.userRole !== "editor" && <Navbaranonuser />} */}
      {localStorage.setItem("bladsy", "Logout")}
      {auth.userName === null && <Navbaranonuser />}
      {auth.userName != null && <Navbar />}      
      {/* </div> */}
      

      <div className="flex flex-col justify-center items-center p-2">
        <h1 className="text-4xl font-bold mb-6">
          <Link to={"/"}>
            {/* <a href="/" className="inline-block">  */}
            {t("logout")}
            {/* </a> */}
          </Link>
        </h1>
        <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg p-3">

        <form onSubmit={logout}>
          <button
            onClick={logout}
            type = "logout"
            className="w-full bg-cyan-700 text-white py-2 mb-4 mt-4 px-4 rounded-lg hover:bg-cyan-800"
          >
            {t("confirmlogout")}
          </button>
          </form>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center p-4 mt-8">
        <Link to={"/"}>
          <img src={Logo} alt="Logo" width="100" height="100" />
        </Link>
      </div>
    </div>
  );
}

export default Logout;
